<template>
  <div style="overflow: hidden">
    <v-btn fab dark color="secondary" class="scrollup-btn" v-if="showScrollup" @click="scrollup">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <!-- web -->
    <div class="d-none d-md-block navbar-class">
      <v-container>
        <div class="d-flex align-center justify-space-between">
          <v-list-item>
            <v-list-item-title>
              <v-img src="../assets/logo.png" max-width="155" height="auto" class="mr-2"></v-img>
            </v-list-item-title>
          </v-list-item>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-btn dark text class="text-h6 text-button" @click="scrollToTarget('about')">
              {{ $t("about") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="scrollToTarget('org')">{{ $t("org") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="scrollToTarget('service')">{{ $t("service") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="$router.push('/activities')">
              {{ $t("activities") }}</v-btn>
            <v-btn dark text class="me-4 text-h6 text-button" @click="scrollToTarget('contact')">{{ $t("contact")
              }}</v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="secondary py-4" v-bind="attrs" v-on="on">
                  <v-icon class="me-1">mdi-web</v-icon>
                  {{ language.title }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </div>
    <!-- h5 -->
    <v-app-bar app dark class="d-block d-md-none" style="height: 72px">
      <v-container>
        <div class="d-flex justify-space-between">
          <v-toolbar-title class="white--text">
            <v-img src="../assets/logo.png" max-width="120" height="auto" class="mr-2"></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="white--text" v-bind="attrs" v-on="on">mdi-web</v-icon>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="white--text ms-4"> mdi-menu </v-icon>
            </template>
            <v-list>
              <v-list-item @click="scrollToTarget('about')">
                <v-list-item-title>{{ $t("about") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('org')">
                <v-list-item-title>{{ $t("org") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('service')">
                <v-list-item-title>{{ $t("service") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/activities')">
                <v-list-item-title>{{ $t("activities") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollToTarget('contact')">
                <v-list-item-title>{{ $t("contact") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
    <div class="header-bg"></div>
    <div class="wow fadeInDown en-content d-flex flex-column align-start" v-if="$i18n.locale === 'en'">
      <P class="font-weight-black font-italic primary--text text-h4 text-sm-h3 text-md-h2">M4ORE SPORT</p>
      <p class="font-italic font-weight-bold text-h6 text-sm-h5 text-md-h4 text-lg-h3">"Become the leader in the sports protection ecosystem,</p>
      <p class="font-italic font-weight-bold text-h6 text-sm-h4 text-lg-h3">facilitating global industry outreach.</p>
    </div>
    <div class="wow fadeInDown content d-flex flex-column align-end" v-else>
      <P class="font-weight-black font-italic primary--text text-h3 text-sm-h2 text-md-h1">M4ORE SPORT</p>
      <p class="text-h6 text-sm-h4 text-lg-h3 font-italic font-weight-bold mt-5">{{ $t("banner_title_1") }}</p>
      <p class="text-h6 text-sm-h4 text-lg-h3 font-italic font-weight-bold">{{ $t("banner_title_2") }}</p>
    </div>
    <!-- C.關於我們 -->
    <div class="about-bg">
      <span class="about-pic-left">
        <v-img src="@/assets/8320479.png" contain></v-img>
      </span>
      <span class="about-pic-right">
        <v-img src="@/assets/8320479.png" contain></v-img>
      </span>
      <div class="about-mask"></div>
      <section id="about">
        <v-container class="py-16">
          <h1 class="text-h4 font-weight-bold my-sm-16 mt-10 text-center">
            {{ $t("about") }}
            <span class="title-decorate"></span>
          </h1>
          <v-row class="d-flex align-center justify-space-around mt-10 mb-16">
            <v-col cols="12" class="mt-10 mt-md-0">
              <p class="wow fadeInDown text-center">
                <span class="primary--text text-h5 font-weight-bold font-italic me-3"><span class="white--text pe-2" v-if="$i18n.locale === 'en'">At</span>M4ORE SPORT</span><span
                  class="text-h6 font-weight-bold">{{ $t("about_describe") }}</span><br />
              </p>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <!-- D.組織介紹 -->
    <div class="org-bg">
      <section id="org">
        <v-container class="py-16">
          <h1 class="white--text text-h4 font-weight-bold mb-16 text-center">
            {{ $t("org") }}
            <span class="title-decorate"></span>
          </h1>
          <br />
          <v-row>
            <v-col v-for="(member, index) in members" :key="index" class="col-12 col-sm-6 col-md-6"
              :class="{ 'col-lg-3': $i18n.locale !== 'en', 'col-lg-6': $i18n.locale === 'en' }">
              <v-card outlined dark class="wow fadeInDown org-card">
                <v-list-item two-line>
                  <v-list-item-avatar tile size="90">
                    <img :src="member.photo" :alt="member.name">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ member.position }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ member.name }}<br>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>

    <!-- E.服務項目 -->
    <div class="service-bg">
      <section id="service">
        <v-container class="py-16">
          <h1 class="white--text text-h4 font-weight-bold mb-16 text-center">
            {{ $t("service") }}
            <span class="title-decorate"></span>
          </h1>
          <h2 class="mt-16 primary--text font-weight-bold font-italic mb-5">
            {{ $t("servie_menu_studio") }}
          </h2>
          <div>
            <v-row>
              <v-col class="wow fadeInDown col-6 col-md-4" data-wow-delay="0.1s">
                <v-card>
                  <v-img src="@/assets/service/1.jpg" gradient="to right, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    class="white--text align-end">
                    <span class="pa-2 ps-4 text-h6" style="
                        background: linear-gradient(90deg, #ab7600, #ffffff00);
                      ">{{ $t("servie_menu_studio_item_protection") }}</span>
                  </v-img>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-6 col-md-4" data-wow-delay="0.2s">
                <v-card>
                  <v-img src="@/assets/service/2.jpg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    class="white--text align-end">
                    <span class="pa-2 ps-4 text-h6" style="
                        background: linear-gradient(90deg, #ab7600, #ffffff00);
                      ">{{ $t("servie_menu_studio_item_massage") }}</span>
                  </v-img>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-6 col-md-4">
                <v-card>
                  <v-img src="@/assets/service/3.jpeg" class="white--text align-end">
                    <span class="pa-2 ps-4 text-h6" style="
                        background: linear-gradient(90deg, #ab7600, #ffffff00);
                      ">{{ $t("servie_menu_studio_item_sportsTraining") }}</span>
                  </v-img>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-6 col-md-4" data-wow-delay="0.3s">
                <v-card>
                  <v-img src="@/assets/service/4.jpeg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    class="white--text align-end">
                    <span class="pa-2 ps-4 text-h6" style="
                        background: linear-gradient(90deg, #ab7600, #ffffff00);
                      ">{{ $t("servie_menu_studio_item_physicalTraining") }}</span>
                  </v-img>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-6 col-md-4" data-wow-delay="0.3s">
                <v-card>
                  <v-img src="@/assets/service/5.jpg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    class="white--text align-end">
                    <span class="pa-2 ps-4 text-h6" style="
                        background: linear-gradient(90deg, #ab7600, #ffffff00);
                      ">{{ $t("servie_menu_studio_item_consulting") }}</span>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="mt-10">
            <v-row>
              <v-col class="wow fadeInDown col-12 col-md-6">
                <h2 class="primary--text font-weight-bold font-italic mt-15">
                  {{ $t("service_menu_promote") }}
                </h2>
                <div class="mt-5 d-flex align-start pa-2">
                  <v-icon class="me-4 error--text">mdi-map-marker</v-icon>
                  <span class="white--text"> {{ $t("service_menu_promote_item1") }}</span>
                </div>
                <div class="mt-5 d-flex align-start pa-2">
                  <v-icon class="me-4 error--text">mdi-school</v-icon>
                  <span class="white--text"> {{ $t("service_menu_promote_item2") }}</span>
                </div>
                <div class="mt-5 d-flex align-start pa-2">
                  <v-icon class="me-4 error--text">mdi-cellphone-message</v-icon>
                  <span class="white--text"> {{ $t("service_menu_promote_item3") }}</span>
                </div>
              </v-col>
              <v-col class="wow fadeInDown col-12 col-md-6">
                <h2 class="primary--text font-weight-bold font-italic mt-15">
                  {{ $t("service_menu_train") }}
                </h2>
                <div class="mt-5 d-flex align-start pa-2">
                  <v-icon class="me-4 error--text">mdi-human-male-board</v-icon>
                  <p class="white--text">
                    {{ $t("service_menu_train_item1") }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
          <h2 class="primary--text font-weight-bold font-italic mt-15">{{ $t("service_menu_enable") }}</h2>
          <div class="my-5">
            <v-row>
              <v-col class="wow fadeInDown col-12 col-sm-6 col-md-3">
                <v-card class="enable-card" elevation="0" height="100%" dark>
                  <v-card-title>{{ $t("service_menu_enable_title1") }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    {{ $t("service_menu_enable_item1") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-12 col-sm-6 col-md-3">
                <v-card class="enable-card" elevation="0" height="100%" dark>
                  <v-card-title>{{ $t("service_menu_enable_title2") }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    {{ $t("service_menu_enable_item2") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-12 col-sm-6 col-md-3">
                <v-card class="enable-card" elevation="0" height="100%" dark>
                  <v-card-title>{{ $t("service_menu_enable_title3") }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    {{ $t("service_menu_enable_item3") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-12 col-sm-6 col-md-3">
                <v-card class="enable-card" elevation="0" height="100%" dark>
                  <v-card-title>{{ $t("service_menu_enable_title4") }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    {{ $t("service_menu_enable_item4") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="wow fadeInDown col-12">
                <v-card class="enable-card" elevation="0" height="100%" dark>
                  <v-card-title>{{ $t("service_menu_enable_title5") }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    {{ $t("service_menu_enable_item5") }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div></div>
        </v-container>
      </section>
    </div>

    <div class="record-bg">
      <!-- F.活動紀錄 -->
      <!-- <section id="record">
        <v-container class="py-16">
          <h1 class="white--text text-h4 font-weight-bold mb-16 text-center">
            {{ $t("record") }}
            <span class="title-decorate"></span>
          </h1>
          <br />
          <v-row>
            <v-col class="wow fadeInDown col-12 col-sm-6 col-md-3" v-for="(item, i) in events" :key="item">
              <v-card>
                <v-img src="https://cdn.vuetifyjs.com/images/cards/house.jpg"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
                </v-img>
                <v-card-actions>
                  <span>{{ item.name }} -
                    <span class="text-body-2">{{ item.date }}</span></span>

                  <v-spacer></v-spacer>
                  <v-icon class="me-2">mdi-heart</v-icon>

                  <v-btn icon @click="showEvent(item)">
                    <v-icon>{{
      showItemId === item.id
        ? "mdi-chevron-up"
        : "mdi-chevron-down"
    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-if="showItemId === item.id">
                    <v-divider></v-divider>
                    <v-card-text>
                      {{ item.explane }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section> -->
      <!-- 聯絡我們 -->
      <section id="contact">
        <v-container class="py-16">
          <h1 class="white--text text-h4 font-weight-bold mb-16 text-center">
            {{ $t("contact") }}
            <span class="title-decorate"></span>
          </h1>
          <br />
          <v-row class="flex-column-reverse flex-sm-row">
            <v-col class="wow fadeInLeft col-12 col-sm-6 col-md-7 col-lg-6" data-wow-delay="0.2s">
              <iframe id="bi_iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.5351757697817!2d121.54351607515953!3d25.083739077784173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ac052cdee7b7%3A0xa36331af9cc35e96!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDlpKfnm7TooZc3MOiZn0jmo58t57ac5ZCI5aSn5qiT!5e0!3m2!1szh-TW!2stw!4v1703661933405!5m2!1szh-TW!2stw"
                frameborder="0" scrolling="auto" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                style="width: 100%; height: 100%;"></iframe>
            </v-col>
            <v-col class="wow fadeInRight col-12 col-sm-6 col-md-5 col-lg-6" data-wow-delay="0.2s">
              <v-card flat class="px-lg-6 px-md-4 px-0" style="background-color: transparent">
                <v-card-text class="text-body-1 grey--text text--lighten-5">
                  {{ $t("contact_title") }}
                </v-card-text>
                <v-card-text>
                  <p class="font-weight-bold mb-1 mb-md-4 text-h6 text-md-h5 grey--text text--lighten-5">
                    {{ $t("contact_subtitle") }}
                  </p>
                </v-card-text>
                <v-divider class="my-1" color="white"></v-divider>
                <v-card-text class="grey--text text--lighten-5">
                  <div class="d-flex mb-6">
                    <v-icon @click="toIG" class="me-3 text-h4 grey--text text--lighten-5">mdi-instagram</v-icon>
                    <v-icon @click="toFB" class="text-h4 grey--text text--lighten-5">mdi-facebook</v-icon>
                  </div>
                  <div class="d-flex align-center mb-4 mb-md-5">
                    <v-icon class="me-2 grey--text text--lighten-5">mdi-email-multiple</v-icon>
                    <span class="text-body-1">sport@m4ore.com</span>
                  </div>
                  <div class="d-flex align-start mb-4 mb-md-5">
                    <v-icon class="me-2 grey--text text--lighten-5">mdi-map-marker-multiple</v-icon>
                    <span class="text-body-1"> {{ $t("address") }}</span>
                  </div>
                  <div class="d-flex align-start">
                    <v-icon class="me-2 grey--text text--lighten-5">mdi-radiobox-marked</v-icon>
                    <span class="text-body-1">{{ $t("qrcode") }}</span>
                  </div>
                  <br>
                  <div class="d-flex">
                    <v-img src="../assets/contact/Line.png" contain max-width="100" height="auto" class="cusor-pointer" @click="openQrcode('line')"></v-img>
                    <v-img class="mx-3 cusor-pointer" src="../assets/contact/IG.png" contain max-width="100" height="auto"  @click="openQrcode('ig')"></v-img>
                    <v-img src="../assets/contact/FB.png" contain max-width="100" height="auto" class="cusor-pointer"  @click="openQrcode('fb')"></v-img>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </section>
    </div>
    <v-dialog v-model="qrCodeDialog" width="300">
      <v-card>
        <v-card-text class="text-center"
          <v-img :src="qrcodeUrl" contain max-width="300" height="auto"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer color="secondary" dark>
      <div class="mx-auto">
        <span class="text-overline"> {{ $t("copyRight") }}{{ new Date().getFullYear() }} — {{ $t("company") }}</span>
      </div>
    </v-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      showItemId: null,
      navbarHeight: 0,
      showScrollup: false,
      qrCodeDialog: false,
      qrcodeUrl:'',
      language: {
        value: "zh-TW",
        title: "繁體中文",
      },
      languageList: [
        {
          value: "zh-TW",
          title: "繁體中文",
        },
        {
          value: "en-US",
          title: "English",
        },
      ],
      slides: [
        {
          src: "https://via.placeholder.com/900x300?text=運動防護1",
          alt: "運動防護1",
        },
      ],
      events: [
        {
          id: "1",
          date: "2023/01/01",
          name: "活動名稱",
          explane:
            "這是活動紀錄~~~~~~~~~~~~~~~Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi nemo doloremquead cum quam sequi, asperiores possimus beatae alias sed ratione expedita dolorum sint! Deserunt cumvero necessitatibus tempore voluptas?",
        },
        {
          id: "2",
          date: "2023/02/15",
          name: "防護活動",
          explane:
            "這是活動紀錄~~~~~~~~~~~~~~~Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi nemo doloremquead cum quam sequi, asperiores possimus beatae alias sed ratione expedita dolorum sint! Deserunt cumvero necessitatibus tempore voluptas?",
        },
        {
          id: "3",
          date: "2023/03/10",
          name: "運動紀錄",
          explane:
            "這是活動紀錄~~~~~~~~~~~~~~~Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi nemo doloremquead cum quam sequi, asperiores possimus beatae alias sed ratione expedita dolorum sint! Deserunt cumvero necessitatibus tempore voluptas?",
        },
        {
          id: "4",
          date: "2023/03/15",
          name: "課程紀錄",
          explane:
            "這是活動紀錄~~~~~~~~~~~~~~~Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi nemo doloremquead cum quam sequi, asperiores possimus beatae alias sed ratione expedita dolorum sint! Deserunt cumvero necessitatibus tempore voluptas?",
        },
      ],
    };
  },
  computed: {
    members() {
      return [
        {
          name: this.$t('org_position1_name'),
          position: this.$t('org_position1'),
          photo: require("@/assets/org/CEO.png"),
        },
        {
          name: this.$t('org_position2_name'),
          position: this.$t('org_position2'),
          photo: require("@/assets/org/consultant.png"),
        },
        {
          name: this.$t('org_position3_name'),
          position: this.$t('org_position3'),
          photo: require("@/assets/org/BM.png"),
        },
        {
          name: this.$t('org_position4_name'),
          position: this.$t('org_position4'),
          photo: require("@/assets/org/mentor.png"),
        },
      ]
    }
  },
  watch: {
    language: function (newValue) {
      if (newValue.title === "English") {
        document.title = "M4ORE SPORTS CO., LTD.";
      } else {
        document.title = "摩爾運動有限公司";
      }
    },
  },
  methods: {
    showEvent(item) {
      this.showItemId = this.showItemId === item.id ? null : item.id;
    },
    changeLanguage(index) {
      this.language = this.languageList[index];
      switch (this.language.value) {
        case "zh-TW":
          this.$i18n.locale = "zh";
          break;
        case "en-US":
          this.$i18n.locale = "en";
          break;
      }
    },
    scrollToTarget(target) {
      const element = document.getElementById(target);
      if (element) {
        const y = this.getElementYPosition(element);
        this.scrollTo(y);
      }
    },
    getElementYPosition(element) {
      const navbarHeight = this.navbarHeight;
      const yOffset = navbarHeight;
      return element.getBoundingClientRect().top + window.pageYOffset - yOffset;
    },
    scrollTo(y) {
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    updateNavbarHeight() {
      this.navbarHeight = this.$refs.navbar.$el.clientHeight;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.showScrollup = scrollTop > 100; // 滾動超過 100 像素時顯示 scrollup 按鈕
    },
    scrollup() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toFB() {
      window.location.assign('https://www.facebook.com/p/M4ORE-SPORT-100095108143077');
    },
    toIG() {
      window.location.assign('https://www.instagram.com/m4oresport_2023/');
    },
    openQrcode(type) {
      if(type === 'line'){
        this.qrcodeUrl = require("@/assets/contact/Line.png");
      }else if(type === 'ig'){
        this.qrcodeUrl = require("@/assets/contact/IG.png");
      }else if(type === 'fb'){
        this.qrcodeUrl = require("@/assets/contact/FB.png");
      }
      this.qrCodeDialog = true;
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateNavbarHeight);
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      new this.$wow().init();
    }),
      (onload = () => { });
  },
};
</script>
<style>
.header-bg {
  height: 85vh;
  background-image: url("../assets/Artboard2.png");
  background-size: cover;
  background-position: center;
  position: relative;
}

.navbar-class {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #00000070;
}

.content {
  position: absolute;
  top: -3%;
  color: #f1f1f1;
  width: 100%;
  left: -40%;
  padding: 355px 0px 0px 122px;
}

.en-content {
  position: absolute;
  top: -2%;
  color: #f1f1f1;
  width: 100%;
  left: 0%;
  padding: 355px 0px 0px 122px;
}

/* 頁面小於960px執行 */
@media screen and (max-width: 768px) {
  .content {
    position: absolute;
    top: 5%;
    width: 100%;
    left: -12%;
    padding: 0px 0px 0px 0px;
  }

  .en-content {
    position: absolute;
    top: -70px;
    color: #f1f1f1;
    width: 100%;
    left: -22%;
    padding: 355px 0px 0px 122px;
  }
}

@media screen and (min-width:768px) and (max-width:960px) {
  .content {
    position: absolute;
    top: 8%;
    width: 100%;
    left: -26%;
    padding: 0px 0px 0px 0px;
  }

  .en-content {
    position: absolute;
    top: 0;
    color: #f1f1f1;
    width: 100%;
    left: -7%;
    padding: 355px 0px 0px 122px;
  }
}

@media screen and (min-width:960px) and (max-width:1200px) {
  .content {
    position: absolute;
    top: 8%;
    width: 100%;
    left: -27%;
    padding: 0px 0px 0px 0px;
  }

  .en-content {
    position: absolute;
    top: 65px;
    color: #f1f1f1;
    width: 100%;
    left: -4%;
    padding: 355px 0px 0px 122px;
  }
}


.title-decorate {
  position: relative;
}

.title-decorate::after {
  content: "";
  width: 145px;
  height: 12px;
  background: #fac03d;
  position: absolute;
  bottom: -10px;
  right: -15px;
}


.about-bg {
  position: relative;
  padding: 35px 0px 35px 0px;
  background: rgba(0, 0, 0, 0.8);
  color: #f1f1f1;
}

.about-pic-right {
  position: absolute;
  bottom: -25px;
  right: 0;
  z-index: 4;
  width: 400px;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .about-pic-right {
    position: absolute;
    bottom: -18px;
    right: 0;
    z-index: 4;
    width: 300px;
    opacity: 0.2;
  }
}

.about-pic-left {
  position: absolute;
  top: 0px;
  left: 0;
  width: 400px;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .about-pic-left {
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
    opacity: 0.2;
  }
}

.service-bg {
  padding: 60px 0px 80px 0px;
  background-image: linear-gradient(to top, rgb(0 0 0 / 51%), #262626b3),
    url('../assets/org.png');
  background-size: cover;
  background-position-y: bottom;
}

.org-bg,
.record-bg {
  padding: 60px 0px 80px 0px;
  background-image: linear-gradient(to top, rgb(0 0 0 / 86%), #262626b3),
    url('../assets/org.png');
  ;
  background-size: cover;
  background-position-y: bottom;
}

.org-card {
  border-radius: 0 !important;
  background-color: #5a5a5a1b !important;
}

.enable-card {
  border-radius: 0 !important;
  background-color: #5a5a5a1b !important;
}

.v-toolbar__content {
  height: 72px !important;
}

.scrollup-btn {
  position: fixed !important;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
}
.cusor-pointer {
  cursor: pointer;
}
</style>
