<template>
  <div class="photos-page white--text">
    <!-- web -->
    <div class="d-none d-md-block navbar-class">
      <v-container>
        <div class="d-flex align-center justify-space-between">
          <v-list-item>
            <v-list-item-title>
              <v-img src="../assets/logo.png" max-width="155" height="auto" class="mr-2"></v-img>
            </v-list-item-title>
          </v-list-item>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-btn dark text class="text-h6 text-button" @click="goToHome('about')">
              {{ $t("about") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="goToHome('org')">{{ $t("org") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="goToHome('service')">{{ $t("service") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="$router.push('/activities')">
              {{ $t("activities") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="$router.push('/photos')">
              {{ $t("photos") }}</v-btn>
            <v-btn dark text class="me-4 text-h6 text-button" @click="goToHome('contact')">{{ $t("contact")
              }}</v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="secondary py-4" v-bind="attrs" v-on="on">
                  <v-icon class="me-1">mdi-web</v-icon>
                  {{ language.title }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </div>

    <!-- h5 -->
    <v-app-bar app dark class="d-block d-md-none" style="height: 72px" color="black">
      <v-container>
        <div class="d-flex justify-space-between">
          <v-toolbar-title class="white--text">
            <v-img src="../assets/logo.png" max-width="120" height="auto" class="mr-2"></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="white--text" v-bind="attrs" v-on="on">mdi-web</v-icon>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="white--text ms-4"> mdi-menu </v-icon>
            </template>
            <v-list>
              <v-list-item @click="goToHome('about')">
                <v-list-item-title>{{ $t("about") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('org')">
                <v-list-item-title>{{ $t("org") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('service')">
                <v-list-item-title>{{ $t("service") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/activities')">
                <v-list-item-title>{{ $t("activities") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/photos')">
                <v-list-item-title>{{ $t("photos") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('contact')">
                <v-list-item-title>{{ $t("contact") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>

    <!-- 内容部分 -->
    <div class="content-wrapper">
      <h1 class="text-h4 font-weight-bold my-6 text-center white--text">{{ $t("photos") }}</h1>
      
      <!-- 相片列表 -->
      <v-container class="mt-6">
        <v-row>
          <v-col v-for="(photo, index) in photos" 
                 :key="index" 
                 cols="12" sm="6" md="4" lg="3">
            <v-card @click="openPhotoDialog(photo)" class="photo-card">
              <v-img
                :src="photo.url"
                :aspect-ratio="1"
                class="grey lighten-2"
                cover
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text class="text-center">{{ photo.title }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- 照片详情 Modal -->
    <v-dialog
      v-model="showPhotoDialog"
      max-width="900px"
    >
      <v-card v-if="selectedPhoto">
        <v-img
          :src="selectedPhoto.url"
          max-height="80vh"
          contain
        ></v-img>
        <v-card-title>{{ selectedPhoto.title }}</v-card-title>
        <v-card-text>{{ selectedPhoto.description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            dark
            @click="showPhotoDialog = false"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'PhotosView',
  data() {
    return {
      photos: [
        {
          url: require('@/assets/service/1.jpg'),
          title: '相片标题 1',
          description: '相片描述 1'
        },
        {
          url: require('@/assets/service/2.jpg'),
          title: '相片标题 2',
          description: '相片描述 2'
        },
        // ... 更多相片
      ],
      showPhotoDialog: false,
      selectedPhoto: null,
      language: {
        value: "zh-TW",
        title: "繁體中文",
      },
      languageList: [
        {
          value: "zh-TW",
          title: "繁體中文",
        },
        {
          value: "en-US",
          title: "English",
        },
      ],
    }
  },
  methods: {
    openPhotoDialog(photo) {
      this.selectedPhoto = photo;
      this.showPhotoDialog = true;
    },
    goToHome(target) {
      this.$router.push('/');
      this.$nextTick(() => {
        const element = document.getElementById(target);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    changeLanguage(index) {
      this.language = this.languageList[index];
      switch (this.language.value) {
        case "zh-TW":
          this.$i18n.locale = "zh";
          break;
        case "en-US":
          this.$i18n.locale = "en";
          break;
      }
    },
  }
}
</script>

<style scoped>
.photos-page {
  background-color: #333333;
  min-height: 100vh;
  color: white;
}

.navbar-class {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #00000070;
  top: 0;
}

.content-wrapper {
  padding-top: 72px;
}

@media (min-width: 960px) {
  .content-wrapper {
    padding-top: 72px;
  }
}

.photo-card {
  transition: transform 0.2s;
  cursor: pointer;
}

.photo-card:hover {
  transform: scale(1.05);
}

.v-card {
  background-color: white !important;
  color: black !important;
}

.v-card .v-card__text {
  color: black !important;
}
</style> 