import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PageNotFound from '../views/PageNotFound.vue';
import ActivitiesView from '../views/ActivitiesView.vue'
import PhotosView from '../views/PhotosView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ig',
    beforeEnter() {
      window.location.assign("https://www.instagram.com/m4oresport_2023", "_blank");
    }
  },
  {
    path: '/activities',
    name: 'activities',
    component: ActivitiesView
  },
  {
    path: '/photos',
    name: 'photos',
    component: PhotosView
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
