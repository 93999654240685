<template>
  <div class="activities-page white--text">
    <!-- web -->
    <div class="d-none d-md-block navbar-class">
      <v-container>
        <div class="d-flex align-center justify-space-between">
          <v-list-item>
            <v-list-item-title>
              <v-img src="../assets/logo.png" max-width="155" height="auto" class="mr-2"></v-img>
            </v-list-item-title>
          </v-list-item>
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-btn dark text class="text-h6 text-button" @click="goToHome('about')">
              {{ $t("about") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="goToHome('org')">{{ $t("org") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="goToHome('service')">{{ $t("service") }}</v-btn>
            <v-btn dark text class="text-h6 text-button" @click="$router.push('/activities')">
              {{ $t("activities") }}</v-btn>
            <v-btn dark text class="me-4 text-h6 text-button" @click="goToHome('contact')">{{ $t("contact")
              }}</v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="secondary py-4" v-bind="attrs" v-on="on">
                  <v-icon class="me-1">mdi-web</v-icon>
                  {{ language.title }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </div>
    <!-- h5 -->
    <v-app-bar app dark class="d-block d-md-none" style="height: 72px" color="black">
      <v-container>
        <div class="d-flex justify-space-between">
          <v-toolbar-title class="white--text">
            <v-img src="../assets/logo.png" max-width="120" height="auto" class="mr-2"></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="white--text" v-bind="attrs" v-on="on">mdi-web</v-icon>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in languageList" :key="index" @click="changeLanguage(index)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="white--text ms-4"> mdi-menu </v-icon>
            </template>
            <v-list>
              <v-list-item @click="goToHome('about')">
                <v-list-item-title>{{ $t("about") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('org')">
                <v-list-item-title>{{ $t("org") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('service')">
                <v-list-item-title>{{ $t("service") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/activities')">
                <v-list-item-title>{{ $t("activities") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToHome('contact')">
                <v-list-item-title>{{ $t("contact") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>

    <!-- 内容部分 -->
    <div class="content-wrapper">
      <h1 class="text-h4 font-weight-bold my-6 text-center white--text">{{ $t("activities") }}</h1>
      
      <!-- 文章列表 -->
      <v-container class="mt-6">
        <h2 class="text-h5 mb-4 white--text">{{ $t("article_list") }}</h2>
        <v-row>
          <v-col v-for="article in articles" 
                 :key="article.id" 
                 cols="12" sm="6" md="4">
            <v-card @click="goToArticle(article.id)" class="h-100">
              <v-img v-if="article.wallpaper" 
                     :src="`https://p.m4ore.com:3053/wallpaper/${article.wallpaper}`" 
                     height="200" 
                     cover>
              </v-img>
              <v-img v-else
                     src="@/assets/service/1.jpg"
                     height="200"
                     cover>
              </v-img>
              <v-card-title>{{ article.title }}</v-card-title>
              <v-card-subtitle>{{ article.subtitle }}</v-card-subtitle>
              <v-card-text>
                <div v-html="article.content.substring(0, 15) + '...'"></div>
                <div class="mt-2">
                  <v-chip v-for="tag in article.tag.split(',')" 
                          :key="tag" 
                          class="mr-2 mb-2" 
                          small>
                    {{ tag }}
                  </v-chip>
                </div>
                <div class="text-caption">{{ formatDate(article.publishDateTime) }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- 添加文章详情 Modal -->
    <v-dialog
      v-model="showArticleDialog"
      max-width="800px"
      scrollable
    >
      <v-card v-if="selectedArticle">
        <v-card-title class="text-h4">{{ selectedArticle.title }}</v-card-title>
        <v-card-subtitle class="text-h6">{{ selectedArticle.subtitle }}</v-card-subtitle>
        <div class="ml-6">
          <div class="mb-4">
            <v-chip 
              v-for="tag in selectedArticle.tag.split(',')" 
              :key="tag" 
              class="mr-2 mb-2" 
              small
            >
              {{ tag }}
            </v-chip>
          </div>
          <div class="text-caption mb-4">{{ formatDate(selectedArticle.publishDateTime) }}</div>
          </div>
        <v-card-text>
          <div class="text-body-1" v-html="selectedArticle.content"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="background-color: #FFC107 !important; color: black !important;"
            @click="showArticleDialog = false"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ActivitiesView',
  data() {
    return {
      articles: [], 
      loading: false,
      error: null,
      language: {
        value: "zh-TW",
        title: "繁體中文",
      },
      languageList: [
        {
          value: "zh-TW",
          title: "繁體中文",
        },
        {
          value: "en-US",
          title: "English",
        },
      ],
      showArticleDialog: false,
      selectedArticle: null,
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    async fetchArticles() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get('https://p.m4ore.com:3053/api/v1/blog/public');
        this.articles = response.data.data;
      } catch (error) {
        console.error('Error fetching articles:', error);
        this.error = '获取文章列表失败，请稍后重试';
      } finally {
        this.loading = false;
      }
    },
    async goToArticle(id) {
      try {
        this.loading = true;
        const response = await axios.get(`https://p.m4ore.com:3053/api/v1/blog/public/${id}`);
        this.selectedArticle = response.data.data;
        this.showArticleDialog = true;
      } catch (error) {
        console.error('Error fetching article:', error);
        // 可以添加错误提示
      } finally {
        this.loading = false;
      }
    },
    goToHome(target) {
      this.$router.push('/');
      // 使用 nextTick 确保路由切换完成后再滚动
      this.$nextTick(() => {
        const element = document.getElementById(target);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    changeLanguage(index) {
      this.language = this.languageList[index];
      switch (this.language.value) {
        case "zh-TW":
          this.$i18n.locale = "zh";
          break;
        case "en-US":
          this.$i18n.locale = "en";
          break;
      }
    },
  },
  created() {
    this.fetchArticles();
  }
}
</script>

<style scoped>
.activities-page {
  background-color: #333333;
  min-height: 100vh;
  color: white;
}

.navbar-class {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #00000070;
  top: 0;
}

.content-wrapper {
  padding-top: 72px;
}

@media (min-width: 960px) {
  .content-wrapper {
    padding-top: 72px;
  }
}

.v-card {
  background-color: white !important;
  color: black !important;
  cursor: pointer;
  transition: transform 0.2s;
}

.v-card:hover {
  transform: translateY(-5px);
}

.v-card .v-card__title {
  color: black !important;
}

.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.6) !important;
}

.v-card .v-card__text {
  color: black !important;
}

.v-chip {
  color: black !important;
  background-color: white !important;
}

.v-chip.v-chip--selected {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

/* 添加 Modal 相关样式 */
.v-dialog {
  background-color: white !important;
  color: black !important;
}

.v-dialog .v-card {
  background-color: white !important;
  color: black !important;
}

.v-dialog .v-card .v-card__title {
  color: black !important;
}

.v-dialog .v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.6) !important;
}

.v-dialog .v-card .v-card__text {
  color: black !important;
}

/* 文章内容样式 */
.text-body-1 :deep(p) {
  margin-bottom: 1rem;
  color: black;
}

.text-body-1 :deep(img) {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

.text-body-1 :deep(a) {
  color: #1976d2;
  text-decoration: none;
}

.text-body-1 :deep(a:hover) {
  text-decoration: underline;
}

/* 文章列表卡片中的标签样式 */
.v-card .v-chip {
  background-color: black !important;
  color: white !important;
}

/* Modal 中的标签样式 */
.v-dialog .v-card .v-chip {
  background-color: black !important;
  color: white !important;
}

/* 选中状态的标签样式（针对筛选器） */
.v-chip-group .v-chip {
  color: black !important;
  background-color: white !important;
}

.v-chip-group .v-chip.v-chip--selected {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
</style> 